@import '_constants.scss';
/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: 'Lato', sans-serif;
  font-style: normal;
}


html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  cursor: default;
  user-select: none;
}

.main-wrapper {
  width: 800px;
  background-color: $secondary-color;
  margin: auto;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #fff;
  padding: 14px;
  border-radius: 10px;
}

.btn {
  outline: 0;
  border: 0;
  border-radius: 23px;
  font-size: 18px;
  font-weight: 500;
  font-family: inherit;
  font-variant-ligatures: none;
  padding: 11px 40px;
  cursor: pointer;
  background-color: $primary-color;
  color: #fff;
  box-shadow: 0 0 0 4px rgba($primary-color, 0.3);
  transition: background-color 0.15s ease;
  &.btn-secondary {
    background-color: transparent;
    color: #fff;
    box-shadow: 0 0 0 2px $primary-color;
    &:hover {
      color: #fff;
      background-color: $primary-color;
    }
  }
  &.btn-disabled {
    background-color: transparent;
    color: $gray;
    pointer-events: none;
    box-shadow: 0 0 0 2px #5e6878;
  }
  &.btn-small {
    font-size: 16px;
    padding: 8px 16px;
  }
  &:hover {
    animation: pulse 1s;
    box-shadow: 0 0 0 2em rgba(#fff, 0), 0 0 0 4px rgba($primary-color, 0.3);
  }
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $primary-color;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $primary-color;
  }
}


.container {
  max-width: 1700px;
  width: 89%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1350px) { 
  .main-wrapper {
    width: 600px;
  }
 }
 @media (max-width: 1125px) { 
  .main-wrapper {
    width: 500px;
  }
 }
